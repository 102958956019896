$font-path: '~atlas/dist/fonts';
@import '~atlas/src/styles/settings/font-geomanist';
@import '~atlas/src/styles/tools/clearfix';
@import '~atlas/src/styles/tools/caret';
@import '~atlas/src/styles/tools/font-size';
@import '~@lumi/header/dist/index.css';

@import '~@lumi/css-variables/dist/index.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  overflow: initial;
}

body {
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  font-family: 'geomanist', Arial;
  color: #4f5a60;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import './styles/variables';
@import './styles/mixins';
@import './styles/common';
@import './styles/responsive';
@import './styles/components/form';
@import './styles/containers/home';
@import './styles/containers/landing';
@import './styles/containers/searchApplication';
@import './styles/containers/verification';
@import './styles/containers/payment';
@import './styles/components/reference';
@import './styles/components/applicationDetail';
@import './styles/components/applicationStatus';
@import './styles/components/timeline';
@import './styles/components/modal';
@import './styles/components/dialog';

@import './styles/pages/page';
@import './styles/components/responsecard';
@import './styles/components/detail';
@import './styles/overrides.scss';

//@import './styles/components/welcome';
//@import './styles/components/cards';
//@import './styles/components/datepicker';
//@import './styles/components/floormap';
//@import './styles/components/cardnode';
//@import './styles/components/contentnode';
//@import './styles/components/planitem';
//@import './styles/components/togglebtns';
//@import './styles/components/terms';
//@import './styles/components/docviewer';
//@import './styles/components/survey';
//@import './styles/components/infocard';
//@import './styles/printable';
