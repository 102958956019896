.home {
  text-align: center;
  padding: 3rem 1.2rem;
  .start-btn {
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }
}

.choose-type {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 2rem 0;
  button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 177px;
    height: 142px;
    width: 100%;
    padding: 0.5rem;
    margin: 0 1.2rem;
    border-radius: 5px;
    border: 1px solid #ced5dc;
    transition: all 0.2s ease;
    outline: none !important;
    position: relative;

    &:hover {
      border-color: #00b0b2;
    }

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
      &:after {
        content: '';
        display: none;
      }
    }

    &.selected {
      border-color: #00b0b2;
      border-width: 2px;
      span {
        color: #00b0b2;
        font-weight: 500;
      }
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 100%;
        z-index: 101;
        width: 18px;
        height: 18px;
        background-position: 4px 5px;
        background-repeat: no-repeat;
        background-size: 10px 8px;
        background-color: #00b0b2;
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTBweCIgaGVpZ2h0PSI4cHgiIHZpZXdCb3g9IjAgMCAxMCA4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1MiAoNjY4NjkpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPnRpY2s8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iMDMtUmV0YWlsZXItU2FsZXMtVG9vbGtpdC0oQ29uc3VtZXIpIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtbGluZWNhcD0icm91bmQiPgogICAgICAgIDxnIGlkPSIwMi1QYXJ0aWN1bGFycyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTI1My4wMDAwMDAsIC0xNzQuMDAwMDAwKSIgc3Ryb2tlPSIjRkZGRkZGIiBzdHJva2Utd2lkdGg9IjIiPgogICAgICAgICAgICA8ZyBpZD0iUHJvZ3Jlc3MiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDcyLjAwMDAwMCwgMTY5LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9InRpY2siIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE4Mi42NjY2NjcsIDYuNTAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTAsMi41IEwyLjUsNSIgaWQ9IkxpbmUiPjwvcGF0aD4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMi41LDUgTDcuMTAyMjMzNzYsMC4zOTc3NjYyNDMiIGlkPSJMaW5lIj48L3BhdGg+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==");
        transform: translate(50%, -50%) scale(2);
      }
    }

    &.false {
      filter: grayscale(1);
    }

    &:after {
      content: 'or';
      display: inline-block;
      text-transform: uppercase;
      position: absolute;
      top: 50%;
      left: calc(100% + 0.5rem);
      font-size: 1.2rem;
      transform: translateY(-50%);
      color: #4f5a60;
    }

    figure {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 0;
      width: 48px;
      height: 48px;
      margin-bottom: 0.5rem;
      .app-icon {
        display: inline-block;
      }
    }

    span {
      color: #4f5a60;
    }

  }
}

.remarks {
  text-align: left;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  ul {
    width: 90%;
    max-width: 350px;
    li {
      margin: .6rem 0;
      ul {
        margin-left: .8rem;
      }
    }
  }
}
