.app-header {
  display: flex;
  position: absolute;
  top: 0;
  padding: 0 0.75rem;
  width: 100%;
  z-index: 1;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.app-footer {
  display: flex;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  color: $color-pepper;
  text-align: center;
  flex-direction: column;

  &__links {
    a {
      margin-right: 10px;
    }
  }

  &__copyright {
    color: #8c969d;
    font-size: 0.75rem;
  }
}

.app-main {
  width: 100vw;
  min-height: calc(100vh - 71px);
  position: relative;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  padding: 3rem 1rem;

  &.is-mobile {
    background: #ffffff;
  }

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 147px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: #ffffff;
    background: -moz-linear-gradient(top, #aeeaeb 0%, #ffffff 100%);
    background: -webkit-linear-gradient(top, #aeeaeb 0%, #ffffff 100%);
    background: linear-gradient(to bottom, #aeeaeb 0%, #ffffff 100%);
    z-index: -1;
  }
}

h1 {
  font-size: 2rem;
  font-weight: 500;
  margin: 0;
  line-height: 1.5;
}

h2 {
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 1.5;
}

h3 {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5;
}

h4 {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5;
}

p {
  font-size: 1rem;
  line-height: 1.6;
}

.highlight {
  color: #00b0b2;
}

.app-loader {
  text-align: center;
}

.page-title {
  text-align: center;
  margin-bottom: 1rem;
}

.lm--button {
  min-width: 160px;
  text-align: center;
  justify-content: center;

  &.is-mobile {
    width: 100%;
    margin-top: 10px !important;
  }

  &.loading {
    position: relative;
    min-height: 44px;
    .btn-loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    & > span {
      display: none;
    }
  }
}

.cui-paper {
  display: flex;
  flex-direction: column;
  min-width: 64px;
  min-height: 64px;
  box-shadow: 0px 2px 8px #e7ecef;
  background-color: #ffffff;
  border-radius: 5px;
  h4 {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  * {
    font-size: 1rem;
  }

  ul,
  ol {
    li {
      margin: 0;
      margin-left: 1rem;
    }
  }
}

.status-details {
  .status-details__title {
    font-size: 1.25rem;
    font-weight: 600;

    &.with-subtitle {
      margin-bottom: 0;

      & + p {
        margin-bottom: 1rem;
      }
    }
  }

  ul {
    li {
      margin-bottom: 1rem;
      line-height: 1.7;
      span {
        display: block;
      }
      & > div {
        display: block;
        margin: 0.5rem 0 1rem;
      }
    }
  }

  .cui-detail_section {
    &__item {
      .cui-label {
        flex-basis: 50%;
        padding-right: 0.75rem;
      }

      .cui-data {
        flex-basis: 50%;
        padding-left: 0.75rem;
      }
    }
  }

  b {
    font-weight: 600;
  }

  &__officer {
    margin-top: 1rem;
  }
}

.status-backdrop {
  display: flex;
  flex-direction: column;
  min-width: 450px;
  min-height: 64px;
  box-shadow: 0px 2px 8px #e7ecef;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 1.5rem;
  margin: 1rem auto;
}

.flex {
  display: flex;
  width: 100%;
}

.inline-flex {
  display: inline-flex;
}

.col {
  flex-direction: column;

  &.align-end {
    align-items: flex-end;
  }

  &.align-start {
    align-items: flex-start;
  }

  &.valign-end {
    justify-content: flex-end;
  }

  &.valign-start {
    justify-content: flex-start;
  }
}

.row {
  flex-direction: row;

  &.align-end {
    justify-content: flex-end;
  }

  &.align-start {
    justify-content: flex-start;
  }

  &.valign-end {
    align-items: flex-end;
  }

  &.valign-start {
    align-items: flex-end;
  }
}

.btn-container {
  &.is-mobile {
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
    position: absolute;
    left: 0;
    bottom: 1%;
    padding-left: 5%;
    padding-right: 5%;
  }
}

.root-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
