@import "../variables";

.form-cta {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  &.from-right {
    justify-content: flex-end;
  }
  &.from-left {
    justify-content: flex-start;
  }
  .lm--button {
    min-width: 150px;
    margin: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
    &:last-child {
      margin-right: 0;
    }
  }
}
form {
  padding: 1.5rem 0;

  .has-error {
    input {
      &[type="text"] {
        border-color: var(--color-red);
      }
    }
  }

  [type="color"],
  [type="date"],
  [type="datetime-local"],
  [type="datetime"],
  [type="email"],
  [type="month"],
  [type="number"],
  [type="password"],
  [type="search"],
  [type="tel"],
  [type="text"],
  [type="time"],
  [type="url"],
  [type="week"],
  select,
  textarea {
    appearance: none;
  }

  @media only screen and (min-width: 768px) {
    .label {
      flex-basis: 40%;
      text-align: right;
      font-size: 1rem;
      align-self: flex-start;
    }
    .control {
      flex-basis: 60%;
    }
  }
}

.cui-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: calc(760px + 2rem);
  padding: 1rem;
  margin: 3rem auto 2rem;

  input,
  select,
  textarea {
    &:disabled {
      background-color: #e7ecef;
      border: 1px solid #bec8ce;
      color: #818a91;
    }
  }

  button {
    &:disabled {
      background-color: #e7ecef;
    }
  }

  .label {
    padding-right: 1.8rem;
    line-height: 1.3;
    position: relative;

    .tooltip-target {
      position: absolute;
      right: 0;
      top: 14px;
    }
  }

  .field-content {
    display: block;
    margin-top: 0.5rem;
    color: #818a91;
    font-size: 0.75rem;
  }

  .field-otp {
    .control {
      .control-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        & > span {
          margin-right: 1rem;
          color: #979797;
          font-size: 0.875rem;
        }
      }

      .field-otp__cont {
        flex-grow: 2;
        & > div {
          width: 44px;
          height: 44px;
          flex-basis: 44px;
          margin-right: 0.5rem;
        }
        .field-otp__input {
          width: 100%;
          height: 100%;
          flex-basis: 100%;
          padding: 0.3rem;
        }
      }
      .field-content {
        & > span {
          display: block;
          margin: 0.5rem 0;
          font-size: 0.875rem;
        }
      }
    }
  }

  .field-cta {
    margin-left: auto;
    width: 100%;
    max-width: 60%;
    justify-content: flex-start;
  }

  .field-checkbox {
    input[name="cs-terms-conditions"] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 20px;
      width: 20px;
      z-index: 10;

      &:checked {
        ~ .checkmark {
          &:after {
            transform: scale(1) rotate(45deg) translate3d(-50%, 0, 0);
          }
        }
      }
    }
    .control {
      display: flex;
      align-items: center;
    }

    .checkmark {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.5rem;
      height: 1.5rem;
      max-width: 1.5rem;
      background-color: #fff;
      border: 1px solid #ced5dc;
      border-radius: 0.3125rem;
      margin-right: 10px;
      cursor: pointer;

      &:after {
        position: absolute;
        top: 2px;
        left: 50%;
        width: 9px;
        height: 15px;
        margin-left: -0.375rem;
        transform: scale(0) rotate(45deg) translate3d(-50%, 0, 0);
        border-color: #00b0b2;
        border-style: solid;
        border-width: 0 2px 2px 0;
        transform-origin: 50% 100%;
        transition: all 0.1s linear;
        content: "";
      }
    }

    .field-content {
      margin-top: 0;
      flex: 1;
      color: #333;
      font-size: 14px;

      .btn-more {
        color: #00b0b2;
        cursor: pointer;
      }
    }
  }

  // Mobile Size
  @media screen and (min-width: 320px) and (max-width: 767px) {
    margin-top: 2rem;
    padding: 0;
    .field-recaptcha {
      .label {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
}
