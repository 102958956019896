.tnc {
  &-modal {
    max-height: 80vh;
    overflow: auto;
    padding: 0 25px !important;
    max-width: 800px !important;

    &__wrapper {
      height: 80vh;
    }

    &__content {
      margin: 20px 0;
    }

    &__close {
      width: 100%;
      padding: 0.5625rem 1rem;
      font-weight: 500;
      line-height: 1.5;
      margin-bottom: 1rem;
      border: 1px solid #00b0b2;
      border-radius: 0.3125rem;
      justify-content: center;
    }

    ul {
      li {
        list-style: none;
      }
    }
  }

  &-list {
    margin-top: 20px;
  }

  &-subitem {
    margin-bottom: 15px;
  }

  &-item {
    margin-bottom: 25px;

    &__title {
      font-size: 1rem;
      font-weight: 600;
    }

    .subitem-wrapper {
      display: flex;
    }

    .order-numb {
      margin-right: 10px;
      width: 25px;
      display: inline-block;
      flex: 0 0 25px;
    }
  }

  &-sublist {
    margin-top: 5px;
  }

  &-subtitle {
    margin: 10px 0;
  }

  &-subcontent {
    margin-top: 5px;
    padding-left: 30px;
    p {
      margin-bottom: 10px;
    }

    span {
      margin-right: 10px;
    }
  }

  &-extracontent {
    margin-top: 20px;
    margin-left: 35px;
  }
}

.btn-back {
  margin-right: 20px !important;

  &.is-mobile {
    margin-left: 20px;
  }
}

#cs-veri-captcha {
  overflow: hidden;
}
