.app-modal {
  &.app-confirmation_dialog {
    padding: 2rem;
    min-width: 550px;
    max-width: 600px;
    @media only screen and (min-width: 768px) and (max-height: 662px) {
      max-height: 600px;
      overflow-y: scroll;
      display: block;
    }

    @media only screen and (max-width: 767px) {
      min-width: 70vw;
      max-width: 100vw;
    }

    & > div {
      &:first-child {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .alert-icon {
      min-height: 100px;
      min-width: 30px;
      margin-top: 2rem;
      margin-bottom: 2rem;
      img {
        width: 100%;
      }
    }

    .modal-ctas {
      margin-top: 2rem;
      button {
        padding: 0.5625em 1em;
        min-height: 60px;
        text-transform: uppercase;
      }
    }
  }

  .app-confirmation_dialog--mobile {
    h3 {
      font-size: 1.25rem;
    }
  }

  &.app-reminder_dialog {
    padding: 2rem;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: 772px;
    .content {
      padding-top: 2rem;
    }
    h3 {
      font-size: 1.5rem;
    }
    .modal-ctas {
      width: 100%;
      justify-content: center;

      button {
        min-width: 175px;
        text-align: center;
        justify-content: center;
        align-items: center;
      }
    }
    .app-accordion {
      margin-bottom: 1rem;
    }
  }
  .content {
    display: block;
    width: 100%;
  }
}
