.payment-container {
  .cui-detail_section {
    &__item {
      margin: 1rem 0;
      padding: 0.75rem 0;

      .cui-label {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        color: $color-pepper;
        font-size: 0.875rem;

        &.multi-title {
          padding: 0.75rem 0;
        }
      }

      &:nth-child(3) {
        padding: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .cui-form {
    margin-top: 0;
  }

  .btn-mimo.lm--button.lm--button--secondary {
    margin-right: 10px;
  }

  .form-cta {
    justify-content: flex-end;
    margin-top: 0;
  }

  .field-cta {
    button:not(:last-child) {
      &.is-mobile {
        margin-left: 1rem;
      }
    }
  }

  b {
    font-weight: 600;
  }

  .cui-choice {
    &::before {
      content: none;
    }

    .cui-choice__btn {
      flex-direction: row;
      padding: 0.625rem 1rem;
      color: #4f5a60;
      cursor: pointer;

      &--title {
        margin-bottom: 0;
        font-weight: unset;
      }

      &__icon {
        display: inline-block;
        height: 25px;
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(-50%);
      }
    }

    &.is-active {
      .cui-choice__btn {
        &::before {
          content: "";
          display: inline-block;
          margin-right: 1rem;
          width: 8px;
          height: 13px;
          border-color: #00b0b2;
          border-style: solid;
          border-width: 0 2px 2px 0;
          transition: all 0.1s linear;
          transform-origin: 0 100%;
          transform: scale(1) rotate(45deg) translate3d(-50%, 0, 0);
        }
      }
    }
  }
}

.payment-details {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 448px;
  min-width: 320px;
  max-width: 640px;
  min-height: 200px;
  margin: 24px 0;
  background-color: #fff;
  outline: none;
  border-radius: 5px;
  box-shadow: 0 2px 20px 1px rgba(231, 236, 239, 0.6);

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    font-size: 0.875rem;

    .detail-title {
      font-weight: 600;
      font-size: 1rem;
    }

    .payment-status {
      color: #fff;
      padding: 8px 16px;
      margin-right: -1rem;
      border-radius: 80px 0 0 80px;

      &.paid {
        background-color: #00b0b2;
      }

      &.unpaid {
        background-color: #e54545;
      }
    }

    .detail-amount {
      font-size: 1.25rem;
      font-weight: 600;
    }

    .detail-amount-label {
      color: #818a91;
    }
  }

  &__wrap {
    &.summary {
      padding: 1rem;

      .payment-details__content {
        background-color: #f3f6f7;
        border-radius: 3px;
      }
    }

    &.invoice {
      margin: 1rem 0;
      position: relative;

      &::before,
      &::after {
        content: "";
        position: absolute;
        border-top: 3px dashed #f3f6f7;
        width: 100%;
      }

      &::before {
        top: -0.5rem;
      }

      &::after {
        bottom: -0.5rem;
      }

      .payment-details__content {
        flex-direction: column;

        .invoice-detail {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 0.5rem;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &__note {
    font-size: 0.75rem;
    color: #818a91;
    text-align: center;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .payment-details {
    width: 100%;
    min-width: 275px;
    max-width: 400px;

    &__content {
      .payment-status {
        padding: 8px 10px;
      }
    }
  }

  .payment-container {
    .cui-detail_section__item {
      margin: 0;

      &:nth-child(3) {
        padding-bottom: 0.75rem;
      }

      .cui-label {
        align-items: flex-start;
      }
    }
  }
}
