@mixin completed {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBzdHJva2U9IiNGRkYiIHN0cm9rZS13aWR0aD0iMiIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtbGluZWNhcD0icm91bmQiPjxwYXRoIGQ9Ik0xLjY2NyA0bDIuNSAyLjVNNC4xNjcgNi41bDQuNjAyLTQuNjAyIi8+PC9nPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 0.825rem;
}

@mixin non-completed {
  background-repeat: initial;
  background-position: initial;
  background-size: initial;
  background-image: none;
}

.cui-progress {
  display: flex;

  &.is-crosswise {
    .cui-progress__items {
      flex-direction: row;
      justify-content: center;
      .cui-progress__item {
        padding: 0 0.5rem;
        padding-top: 2.25rem;
        position: relative;
        text-align: center;
        flex-basis: 12.5rem;
        width: 12.5rem;
        &.is-active {
          &:after {
            background-color: #e7ecef;
          }
        }
        &:first-child {
          padding-top: 2.25rem;
          &:after {
            display: none;
          }
        }
        &:last-child {
          &:after {
            display: block;
          }
        }
        &:before {
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }
        &:after {
          width: 100%;
          height: 0.125rem;
          top: 0.60rem;
          right: 50%;
          left: auto;
          // background-color: #00b0b2;
        }
      }
    }
  }

  &.is-progress {
    &.is-crosswise {
      .cui-progress__items {
        .cui-progress__item {
          &.is-active {
            &:after {
              background-color: #00b0b2;
            }
          }
        }
      }
    }

    .cui-progress__items {
      .cui-progress__item {
        &.is-active {
          font-weight: 400 !important;

          &:before {
            @include non-completed;
            background-color: #00b0b2;
            border-color: #00b0b2;
          }
          &:after {
            background-color: #00b0b2;
            border-color: #00b0b2;
          }
          & ~ .cui-progress__item {
            &:before {
              @include non-completed;
              background-color: #fff;
              border-color: #e7ecef;
            }
            &:after {
              background-color: #e7ecef;
            }
          }
        }
        &:before {
          background-color: #00b0b2;
          border-color: #00b0b2;
          @include completed;
        }
        &:after {
          background-color: #00b0b2;
        }
      }
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;
    padding: 1rem 0;
    width: 100%;
  }
  &__item {
    position: relative;
    padding-top: 3rem;
    padding-left: 2rem;
    &:first-child {
      padding-top: 0;
      &:before {
        bottom: calc(100% - 1.25rem);
      }
      &:after {
        display: none;
      }
    }
    &.is-active {
      &:before {
        @include non-completed;
        background-color: #00b0b2;
        box-shadow: inset 0 0 0 0.1875rem #fff !important;
        border-color: #00b0b2;
      }
      &:after {
        background-color: #e7ecef;
        border-color: #e7ecef;
      }
      & ~ .cui-progress__item {
        &:before {
          @include non-completed;
          background-color: #fff;
          border-color: #e7ecef;
        }
        &:after {
          background-color: #e7ecef;
        }
      }
    }
    &:before {
      content: "";
      display: inline-block;
      background-color: #ffffff;
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 100%;
      border: 0.125rem solid #e7ecef;
      z-index: 2;
      position: absolute;
      bottom: calc(100% - 3rem - 1.25rem);
      left: 0;
      @include non-completed;
      transition: all .5s ease;
    }
    &:after {
      content: "";
      display: inline-block;
      width: 0.125rem;
      height: calc((100% - (1.25rem / 2)) + 0.625rem);
      background-color: #e7ecef;
      position: absolute;
      bottom: calc(0% + 1.25rem);
      left: calc(0.625rem - 0.125rem / 2);
      z-index: 1;
      transition: all .5s ease;
    }
  }
}
