// Desktop
@media only screen and (min-width: 1025px) {
  :root {
    font-size: 16px;
  }

  .app-modal {
    &.app-confirmation_dialog {
      max-width: initial;
    }
  }

  .page-menu {
    .app-cardselection {
      bottom: 45%;
      transform: translateX(-50%) translateY(50%);

      .app-cardselection__item {
        width: 20.5vw;
        height: 26.8vw;

        h3 {
          font-size: 1.6vw;
        }
      }
    }
  }
}

.scp-mobile {
  .app-modal {
    &.app-confirmation_dialog {
      text-align: center;
      min-width: 80vw;

      .modal-ctas {
        button,
        .lm--button {
          font-size: 0.875rem;
        }
      }
    }

    &.tnc-modal {
      font-size: 0.875rem;
    }
  }
}

// Mobile
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .app-main {
    padding-top: 3.5rem;
    & h1 {
      &.app-title {
        margin-top: 0.5rem;
      }
    }
  }

  .app-header {
    & svg {
      width: 8.4rem;
    }
  }

  .responsive-warn {
    display: flex;

    .warn-mobile {
      display: block;
    }
  }

  .app-page {
    &--header {
      margin-top: 1.5rem;
      text-align: center;
    }
    .form-cta {
      padding-top: 0;
    }
  }

  .cui-form {
    .form-cta {
      .lm--button {
        min-width: 130px;
      }
    }
  }

  .cui-field_cta {
    button {
      padding: 0.5625rem 0.75rem;
    }
  }

  .cui-tabs {
    .cui-tabs__content {
      .cui-tabs__item {
        box-shadow: none;
        &.is-type-progress {
          .cui-tabs__itemNav {
            font-size: 1rem;
            &:after {
              top: calc(0% + 1.5rem) !important;
            }
          }
        }
      }
    }
  }

  .cui-form {
    .field-cta {
      max-width: 100% !important;
      justify-content: center !important;
    }
  }

  .modal-ctas {
    button {
      min-width: 120px;
      min-height: 25px !important;
    }
  }

  .status-backdrop {
    min-width: 350px;
  }

  .payment-details {
    width: 100%;
    min-width: 275px;
    max-width: 400px;

    &__content {
      .payment-status {
        padding: 8px 10px;
      }
    }
  }

  .payment-container {
    .cui-detail_section__item {
      .cui-label {
        align-items: flex-start;
      }
    }
  }
}

@media only screen and (min-width: 800px) and (max-width: 830px) and (min-height: 375px) and (max-height: 420px) {
  .responsive-warn {
    display: flex;

    .warn-mobile {
      display: block;
    }
  }
}

// Tablet

@media only screen and (min-width: 768px) and (max-width: 1100px) {
  .app-welcome {
    width: 60vw;
  }

  .app-welcome__cta {
    width: 4.3em;
    height: 4.3em;
  }

  .page-menu {
    .app-cardselection {
      .app-cardselection__item {
        height: 33vw;

        h2 {
          font-size: 2rem;
          margin: 1rem 0 0.2rem;
        }

        h3 {
          font-size: 1.3rem;
          margin: 0;
        }

        figure {
          width: 12vw;
          height: 12vw;

          img {
            width: auto;
            height: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .responsive-warn {
    display: flex;

    .warn-tablet--portrait {
      display: block;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1366px) {
  :root {
    font-size: 16px;
  }

  .app-page {
    &.type-generic {
      .app-page__container {
        // padding: calc(147px + 5rem) calc((100% - 42vw) / 2) 1.5rem;
      }
    }
  }

  .page-menu {
    .app-cardselection {
      bottom: 45%;
      transform: translateX(-50%) translateY(50%);

      .app-cardselection__item {
        width: 20.55vw;
        height: auto;
        min-height: 30.75vw;
      }
    }
  }

  .app-modal {
    &.app-confirmation_dialog {
      max-width: 550px;
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1366px) and (max-height: 1024px) and (orientation: landscape) {
  .page-menu {
    .app-cardselection {
      bottom: 45%;
      transform: translateX(-50%) translateY(50%);

      .app-cardselection__item {
        width: 20.55vw;
        height: auto;
        min-height: 26vw;
        padding-top: 3vw;

        h3 {
          font-size: 1.9vw;
        }
      }
    }
  }
}
