.responsecard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 420px;
  padding: 1rem;
  margin: 5rem auto;
  text-align: center;

  h2 {
    margin: 1rem 0;
  }
  .responsecard-content {
    margin-bottom: 2rem;
    font-size: 1rem;
  }
}
