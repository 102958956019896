.app-page {
  justify-content: flex-start;
  &--header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 2.5rem;
    max-width: 520px;
    text-align: center;
    max-width: 100%;
    h1 {
      margin-bottom: 1rem;
      font-weight: 600;
    }
  }

  & > section {
    width: 100%;
    max-width: 775px;
    margin: 1rem auto;
  }

  .cui-survey {
    max-width: 976px;
  }

  @media screen and (min-width: 320px) and (max-width: 767px) {
    .app-page {
      &--header {
        text-align: center;
      }
    }
  }
}
