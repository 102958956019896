.landing {
  &-subtitle {
    font-size: 0.9rem;
    color: $pepper-colour;
    max-width: 550px;
    text-align: center;
  }

  &-btncheck {
    margin-top: 10px;

    button {
      @media screen and (max-width: 480px) {
        min-width: 120px;
      }
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 410px;
    margin-top: 40px;

    .choose-type {
      margin: 0 0 2rem;
      button {
        max-width: 224px;
        height: 168px;

        figure {
          margin-bottom: 1.5rem;
          width: 60px;
        }

        &.selected {
          &:before {
            width: 10px;
            height: 10px;
            background-position: center;
            background-size: 6px 4px;
          }

          figure {
            svg {
              fill: $teal-colour;
            }
          }

          span {
            font-weight: 600;
          }
        }

        &.rm-after {
          &:first-child {
            margin-right: 0.875rem;
          }

          &:last-child {
            margin-left: 0.875rem;
          }

          &::after {
            content: "";
          }
        }
      }
    }

    .btn-more {
      outline: none;
      padding: 0;
      margin: 0 4px;
    }
  }

  &-info {
    padding: 0 20px;

    &.is-mobile {
      padding-bottom: 75px;
    }

    li {
      margin-bottom: 20px;
      font-size: 0.9rem;
    }
  }

  &-modal {
    min-width: 640px !important;
    border-radius: 0.4rem !important;
    padding: 3rem !important;
    max-height: 90vh;
    overflow: auto;

    @media screen and (min-width: 320px) and (max-width: 767px) {
      min-width: 85vw !important;
    }

    &__wrapper {
      width: 100%;
      min-height: 70vh;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 480px) {
        max-height: 90vh;
      }
    }

    &__content {
      flex: 1;
    }

    &__title {
      margin-bottom: 0 !important;
      margin-top: 10px;
      font-weight: 600;
      text-align: center;
    }

    &__subtitle {
      font-size: 0.8rem !important;
      text-align: center;
    }

    &__close {
      position: absolute;
      right: 28px;
      top: 30px;
      width: 20px;
      height: 20px;
      cursor: pointer;

      @media screen and (max-width: 480px) {
        right: 15px;
        top: 5px;
        width: 15px;
        height: 15px;
      }

      &:hover {
        opacity: 0.6;
      }

      &:before,
      &:after {
        position: absolute;
        left: 15px;
        content: " ";
        height: 21px;
        width: 2px;
        background-color: $space-colour;

        @media screen and (max-width: 480px) {
          height: 16px;
        }
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }

    &__notes {
      .title {
        font-size: 0.875rem;
        font-weight: 600;
        margin-bottom: 1rem;
      }
      ul {
        li {
          list-style: none;
          font-size: 0.8rem;
          color: $pepper-colour;
        }
      }

      @media screen and (max-width: 480px) {
        margin-bottom: 20px;
      }
    }

    .cui-tabs {
      width: 100%;

      .cui-tabs__nav {
        flex-basis: 100%;

        @media screen and (min-width: 320px) and (max-width: 767px) {
          display: block;
        }

        .cui-tabs__item {
          position: relative;
          padding: 0 0 0 1.8rem;
          width: 100%;
          cursor: pointer;
          overflow: hidden;

          &:last-child {
            &:after {
              display: none;
            }
          }

          .content {
            display: none;
            margin-top: 10px;

            &-item {
              margin-bottom: 1rem;
            }

            p {
              font-size: 0.875rem;
              margin: 0;
            }

            .note {
              display: inline-block;
              color: $pepper-colour;
              position: relative;
              padding-left: 15px;
              font-size: 0.875rem;

              &:before {
                content: "";
                display: inline-block;
                width: 10px;
                height: 1px;
                background-color: $pepper-colour;
                position: absolute;
                top: 10px;
                left: 3px;
              }
            }
          }

          .desc {
            font-size: 0.875rem;
            color: $pepper-colour;
            margin-bottom: 24px;
            display: flex;

            svg {
              margin-right: 5px;
            }

            sup {
              top: 4px;
            }

            span {
              display: flex;
              margin-right: 5px;
            }
          }

          .title {
            height: 50px;
            position: relative;

            .arrow {
              display: inline-block;
              vertical-align: 4px;
              margin-left: 5px;
              width: 8px;
              height: 8px;
              box-sizing: border-box;
              transform: rotate(135deg);

              &::before {
                content: "";
                width: 100%;
                height: 100%;
                border-width: 2px 2px 0 0;
                border-style: solid;
                border-color: $pepper-colour;
                transition: 0.2s ease;
                display: block;
                transform-origin: 100% 0;
              }

              &:after {
                content: "";
                float: left;
                position: relative;
                top: -100%;
                width: 100%;
                height: 100%;
                border-width: 0 0 0 0;
                border-style: solid;
                border-color: $pepper-colour;
                transform-origin: 100% 0;
                transition: 0.2s ease;
              }
            }
          }

          &::before {
            border: 0.125rem solid $grey200-colour;
            background-color: #fff;
            display: inline-block;
            position: absolute;
            top: 1px;
            left: 0;
            border-radius: 100%;
            width: 1.25rem;
            height: 1.25rem;
            z-index: 2;
            content: "";
          }

          &:after {
            content: "";
            display: inline-block;
            width: 0.125rem;
            height: 100%;
            background-color: $grey200-colour;
            position: absolute;
            bottom: 0;
            left: calc(0.625rem - 0.125rem / 2);
            z-index: 1;
            transition: all 0.5s ease;
          }

          &.is-active {
            &::before {
              background-color: $teal-colour;
              border-color: $teal-colour;
              box-shadow: inset 0 0 0 0.1875rem #fff;
            }
            .content {
              display: block;
              margin-bottom: 1rem;
            }

            .title {
              height: auto;
              color: $teal-colour;

              .arrow {
                transform: rotate(-45deg);
                vertical-align: 0;
              }
            }
          }
        }

        &.non-progress {
          .cui-tabs__item {
            padding: 0;
            margin-bottom: 1rem;

            .content {
              padding-left: 1.5rem;

              &-item {
                margin-bottom: 0;
                list-style-type: decimal;
              }
            }

            .title {
              font-weight: 500;
              height: 40px;
              color: #4f5a60;
              display: flex;
              justify-content: space-between;

              .arrow {
                margin-right: 5px;
              }
            }

            &::before {
              display: none;
            }

            &::after {
              content: "";
              display: inline-block;
              height: 0.05rem;
              width: 100%;
              background-color: #e7ecef;
              position: absolute;
              bottom: 0;
              left: 0;
              z-index: 1;
              transition: all 0.5s ease;
            }

            &.is-active {
              .title {
                color: inherit;
                height: auto;
              }
            }
          }
        }
      }
    }
  }
}
