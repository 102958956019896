#g-recaptcha {
  overflow: hidden;
}
//#g-recaptcha > div > div > div {
//  margin: auto;
//}

.reference {
  .header {
    text-align: center;
  }

  .notice {
    margin: 1rem 0;
    display: flex;
    flex-flow: row wrap;


    @media only screen and (min-width: 768px) {
      justify-content: flex-end;
      div, p {
        flex-basis: 60%;
      }
    }

  }
}