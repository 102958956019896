$opacity: 0.6;
.status-figure {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;

  .state-header {
    display: flex;
    flex-direction: column;
    flex-basis: 30%;
    text-align: right;

    @media only screen and (max-width: 767px) {
      text-align: left;
    }

    .status-figure--title {
      margin-bottom: 1rem;
      font-weight: 600;
      min-height: 2.5rem;
    }

    .state {
      display: block;
      height: 50px;
    }
  }

  .state-details {
    display: flex;
    flex-basis: 70%;
  }

  .utilities {
    .util-col {
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-grow: 1;
      flex-direction: column;

      .col-header {
        margin-bottom: 1rem;
        font-weight: 600;
        min-height: 2.5rem;
      }

      .status-group {
        display: block;

        ul {
          height: 100%;
          li.blur {
            opacity: $opacity;
          }
          li.blink::before {
            opacity: $opacity;
          }

          li.blink::after {
            animation: 1s linear infinite blink both;
          }
        }

      }
    }
  }
}

// Animation
@keyframes blink {
  from, to {
    opacity: $opacity;
  }

  50% {
    opacity: 1;
  }
}
