.application-detail {
  .detail_section {
    .detail_section--title {
      margin-bottom: 1rem;
    }
    h4 {
      font-size: 1rem;
    }

    .detail_section--content {
      padding-bottom: 2rem;

      .u-grid {
        padding-bottom: .5rem;
        .label {
          @media only screen and (min-width: 768px) {
            text-align: right;
          }
        }
      }
    }
    .col-md-4 {
      padding-right: 1.5rem;
    }
  }


  @media only screen and (min-width: 768px) {
    .u-grid {
      .label {
        text-align: right;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .lm--formItem--inline {
      display: flex;
    }
  }

  .notice {
    display: flex;
    justify-content: center;
    p {
      text-align: left;
      max-width: 650px;
      @media only screen and (min-width: 1170px) {
        max-width: 900px;
      }
    }
  }
}