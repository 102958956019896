$primaryColor       :       rgba(0,176, 178, 1);
// primary
$space-colour       :       #4f5a60;
$white-colour       :       #ffffff;
$pepper-colour      :       #818a91;
$teal-colour        :       #00b0b2;

// basic
$grey100-colour     :       #f3f6f7;
$grey200-colour     :       #e7ecef;
$grey300-colour     :       #ced5dc;
$grey400-colour     :       #bec8ce;

// secondary
$red-colour         :       rgb(229, 69, 69);