$timelineIndicatorSize: 18px;
$timelineStateSize: 50px;

.lm--timeline {
  &.vertical {
    flex-direction: column;

    li {
      width: $timelineIndicatorSize;
      flex-basis: $timelineStateSize;
      padding-top: 0;

      &.active {
        &:before {
          background-color: var(--completed-bg-color);
        }

        &:after {
          box-shadow: inset 0 0 0 3px #fff;
          background: #00b0b2;
          background: var(--completed-bg-color);
          border: 2px solid #00b0b2;
          border: 2px solid var(--completed-bg-color);
          content: "";
        }
      }

      &:first-child {
        &:before {
          display: none;
        }
      }

      &:before {
        content: "";
        width: 2px;
        height: calc(100% - (#{$timelineIndicatorSize} - 2px));
        top: auto;
        left: 50%;
        bottom: 100%;
        transform: translateX(-50%);
        border: 0;
        border-radius: 0;
        background: none;
        background-color: var(--bg-color);
        box-shadow: none;
        z-index: 1;
      }

      &:after {
        content: "";
        display: block;
        width: $timelineIndicatorSize;
        height: $timelineIndicatorSize;
        position: absolute;
        top: 0;
        left: 50%;
        border: 2px solid var(--bg-color);
        border-radius: 100%;
        background-color: var(--bg-color);
        box-shadow: inset 0 0 0 7px #fff;
        transform-origin: center;
        transform: translateX(-50%);
        z-index: 2;
      }
    }
  }
}
