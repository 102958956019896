/* Fixed Button-related components */
.btn--mimo {
  /* Fixed Text Color */
  &.lm--button--primary:disabled {
    color: #9ba6aa;
  }

  /* Fixed Button Backward or Cancel Type */
  &.lm--button--primary:not(:disabled),
  &.lm--button--danger {
    color: #fff;

    &:hover {
      color: #fff;
    }
  }

  /* Fixed Button Tick */
  &.lm--switch-input {
    & + .lm--switch-label {
      border-color: #ced5dc;

      &:hover {
        border-color: #158693;
      }
    }

    &:checked {
      & + .lm--switch-label {
        -webkit-align-items: center;
        align-items: center;

        &::before {
          width: 8px;
          height: 13px;
        }

        &::after {
          content: none;
        }
      }
    }
  }

  /* Fixed Button Border */
  &.lm--button--secondary {
    color: #00b0b2;
    border: 1px solid #00b0b2;
  }

  /* Fixed Hovering Border */
  &.lm--button--secondary:hover {
    border-color: #158693;
  }
}
