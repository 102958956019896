.cui-detail_section {

  &__title {
    margin-bottom: 1rem;
    .cui-label {
      h4 {
        font-weight: 600;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    width: 100%;
    line-height: 1.5;
    margin: 0.5rem 0;
    .cui-label {
      color: $color-pepper;
      text-align: right;
      padding-right: 1rem;
    }
  }

  .cui-label {
    flex-basis: 38.58%;
  }

  .cui-data {
    flex-basis: 61.42%;
    padding-left: 1rem;
  }

  @media screen and (min-width: 320px) and (max-width: 767px) {
    .cui-detail_section {
      &__item {
        flex-direction: column;

        .cui-label {
          text-align: left;
        }

        .cui-data {
          padding-left: 0;
        }
      }
      .cui-label {
        flex-basis: 100%;
      }
      .cui-data {
        flex-basis: 100%;
      }
    }
  }
}
